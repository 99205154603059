/* eslint-disable no-useless-escape */

// eslint-disable-next-line no-undef
const _thirdPartyUrl = process.env.GIVOL_REQUEST_URL;
const _baseUrl = '/api/v1';
const urls = {
  connections: `${_baseUrl}/connections`,
  devices: `${_baseUrl}/devices`,
  additionals: `${_baseUrl}/additionals`,
  tariffs: `${_baseUrl}/tariffs`,
  address: `${_baseUrl}/address`,
  brigades: `${_thirdPartyUrl}/brigades`,
};

const app = {
  init: function () {
    app.windowResize();
    app.navigation();
    app.modals();
    app.menu();
    app.sliders();
    app.paralax();
    app.allscript();
    app.selectric();
    app.rangeSingle();
    app.tabs();
    app.validate();
    app.accordeon();
    app.uTabs('.js-u-tabs');
    app.tariffMechanicsInfoExpander();
    app.loginVariations();
    app.forgotPassword();
    app.newPassword();
    app.login2fa();
    app.requestConnectionsStep();
    app.requestDevicesStep();
    app.requestAddServStep();
    app.requestPage();
    app.passwordEye();
    app.savePassword();
    app.forgotPassword();
    app.mapPage();
    app.hardwarePage();
    app.navigation();
    app.channelsSlider();
    app.showСharacteristics();
    app.passiveListenerForTariffs();
  },

  fetchData: async (url) => {
    try {
      const resp = await fetch(url);

      if (resp.status < 200 && resp.status >= 300) {
        throw new Error(`Error with status: ${resp.status}`);
      }

      return await resp.json();
    } catch (e) {
      throw new Error(e);
    }
  },

  navigation: function () {
    const location = window.location.pathname.slice(1);

    document.querySelectorAll('[data-nav-category]').forEach(a => {
      if (a.dataset.navCategory === location) {
        a.classList.add('active');
        localStorage.setItem('history', location);
      }
    });

    const history = localStorage.getItem('history');

    setTimeout(() => {
      document.querySelector(`[data-nav-category="${history}"]`)?.classList.add('active');
      if (history === 'internet-tv-home') {
        $('a[data-nav=\'internet-tv\']')[0] ? $('a[data-nav=\'internet-tv\']')[0].dataset.nav = 'internet-tv-home' : null;
        $('a[data-nav=\'internet\']')[0].style.display = 'none';
        $('a[data-nav=\'tv-unlimited\']')[0].style.display = 'none';
        $('a[data-nav=\'internet-tv-home\']')[0].href = window.location.origin + '/internet-tv-home';
      }

      document.querySelector(`a[data-nav="${location}"]`)?.classList.add('active');
    }, 200);
  },

  paralax: function () {
    $(window).on('load', function () {
      $('.jsParalax').each(function (i, el) {
        function getRandomArbitrary() {
          return Math.random() * (30 - 10) + 10;
        }

        function getRandomDuration() {
          return Math.random() * (3 - 1) + 1;
        }

        setInterval(function () {
          const offsetX = getRandomArbitrary(1);
          const offsetY = getRandomArbitrary(2);
          const dur = getRandomDuration(1);

          const translate = 'translate3d(' + Math.round(offsetX) + 'px,' + Math.round(offsetY) + 'px, 0px)';

          const duration = dur + 's';

          $(el).css({
            '-o-transition-duration': duration,
            '-moz-transition-duration': duration,
            'transition-duration': duration,
            '-webkit-transform': translate,
            transform: translate,
            'moz-transform': translate,
            '-webkit-transition-duration': duration
          });
        }, 2500);
      });
    });
  },

  hardwarePage: function () {
    if (!document.getElementById('b_hardware')) {
      return;
    }
    const filterItems = document.querySelectorAll('.filter-item');
    const informationItems = document.querySelectorAll('.information_item');
    const items = document.querySelectorAll('.b_hardware_products .products_item');

    filterItems.forEach(item => {
      item.addEventListener('click', filterProducts);
    });
    app.filterAnyItems(items, 'routers');

    function filterProducts(e) {
      const type = filterItems[0].dataset.type;
      if (e === undefined) {
        filterItems[0].classList.add('active');
        document.querySelector(`.information_item[data-type='${type}']`).classList.add('active');
        app.filterAnyItems(items, type);
        return;
      } else {
        clearFilter();
        const target = e.target;
        target.classList.add('active');
        document.querySelector(`.information_item[data-type='${target.dataset.type}']`).classList.add('active');
        app.filterAnyItems(items, target.dataset.type);
      }
    }

    function clearFilter() {
      items.forEach(item => item.classList.remove('active'));
      filterItems.forEach(item => item.classList.remove('active'));
      informationItems.forEach(item => item.classList.remove('active'));
    }

    filterProducts();
  },

  passwordEye: function () {
    if (!$('.eye').length) {
      return;
    }

    $('.eye').on('click', function (e) {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).siblings('input[type="text"]').attr('type', 'password');
      } else {
        $(this).addClass('active');
        $(this).siblings('input[type="password"]').attr('type', 'text');
      }

      e.preventDefault();
    });
  },

  getFilteredDevices: (arr, type) =>
    arr.filter(device =>
      device.categories.length && type === device.categories[0].name
    ),

  appendDeviceToContainer: (devicesArr, container) => {
    if (!devicesArr || !devicesArr.length) return;

    devicesArr.forEach((device, i) => {
      const mainCharacteristicsArr = device.attribute?.filter(attr => +attr.attibute_primary === 1);
      const characteristicsArr = device.attribute?.filter(attr => +attr.attibute_primary === 0);

      let mainCharacteristicsMarkup = `
                  <div class="card_promInfo">
                      <div class="card_promInfo-title"><strong>Основні характеристики:</strong></div>
                      <div>
              `;
      mainCharacteristicsArr.length ?
        mainCharacteristicsArr.forEach(attr => {
          mainCharacteristicsMarkup += `
                          <div class='card_promInfo-text'><span>${attr.attribute_name}:</span> <strong>${attr.attribute_value}</strong></div>
                      `;
        }) :
        '<div class=\'card_promInfo-text\'><strong>-</strong></div>';
      mainCharacteristicsMarkup += `
                      </div>
                  </div>
              `;

      let characteristicsMarkup = `
                  <div class='characteristic'>
                      <p><strong class='characteristic-title'>Характеристики:</strong></p>
              `;
      characteristicsArr.length ?
        characteristicsArr.forEach(attr => {
          characteristicsMarkup += `
                          <p>${attr.attribute_name}: <strong>${attr.attribute_value}</strong></p>
                      `;
        }) :
        characteristicsMarkup += '<p>-</p>';
      characteristicsMarkup += `
                  </div>
              `;

      let iconsMarkup = '<ul class="card_icons">';
      device.icons?.forEach(icon => {
        iconsMarkup += `<li><img src="/storage/app/media${icon.image}" alt="${icon.name}" title="${icon.name}"></li>`;
      });
      iconsMarkup += '</ul>';

      const card = $(`
        <div class="card_item" id="device_${i}">
            <div class="item_wrapper">
                <div class="card_img"><img src="/storage/app/media${device.image}" alt="${device.name}" title="${device.name}"></div>
                <h3 class="card_title">${device.name}</h3>

                ${mainCharacteristicsMarkup ? mainCharacteristicsMarkup : ''}

                ${iconsMarkup ? iconsMarkup : ''}

                <div class="equipment_price">
                    <div class="price_wrapper">
                    <div class="new_price"><span class="new_price_router">${+device.price}</span><span>грн</span></div>
                    <div class='card_by-btn bl-shadow jsBtnBuyRequesPage'>
                        <span class='btn-circle'></span>
                        <span class='btn-rols'></span>
                    </div>
                    </div>
                </div>

                ${characteristicsMarkup ? characteristicsMarkup : ''}
            </div>
        </div>
    ` );

      $(container).append(card);
    });
  },

  requestConnectionsStep: async () => {
    if (!$('.r-step-3').length) return;

    const fetchedData = await app.fetchData(urls.connections);

    if (!fetchedData || !fetchedData.length) return;

    const containerHRB = $('.connection_type_HighRiseBuilding .d_flex');
    const containerPH = $('.connection_type_PrivateHouse .d_flex');

    fetchedData.forEach(element => {
      const itemPrice =
        element.price.indexOf('.') !== -1
          ? element.price.split('.')[1] === '00'
            ? element.price.split('.')[0]
            : element.price
          : element.price;

      const connectionItemMarkup = `<label class="level_item bl_shadow">
        <input type="radio" name="radio_cabel" value="${element.name}">
        <div class="level_inner">
          <div class="item_icon">
            <img src="${element.icon}" alt="${element.name}">
          </div>
          <div class="item_title">${element.name}</div>
          <div class="item_price"><span class='price'>${itemPrice}</span> грн</div>
          <div class="item_text">${element.description}</div>
          <div class="item_sub_title">${element.advantage}</div>
        </div>
      </label>`;

      element.type === 'LAN' && containerHRB.append(connectionItemMarkup);
      element.type === 'GPON' && containerPH.append(connectionItemMarkup);
    });
  },

  requestDevicesStep: async () => {
    if (!$('.r-step-4').length) return;

    const fetchedData = await app.fetchData(urls.devices);

    const routersArrow = app.getFilteredDevices(fetchedData, 'Сетевые карты');
    const playersArrow = app.getFilteredDevices(fetchedData, 'Приставки');
    const tvsArrow = app.getFilteredDevices(fetchedData, 'Телевизоры');

    app.appendDeviceToContainer(routersArrow, '#equipment_block_routers .card_wrapper');
    app.appendDeviceToContainer(playersArrow, '#equipment_block_players .card_wrapper');
    app.appendDeviceToContainer(tvsArrow, '#equipment_block_tvs .card_wrapper');
  },

  requestAddServStep: async () => {
    if (!$('.r-step-5').length) return;

    const fetchedData = await app.fetchData(urls.additionals);

    if (!fetchedData || !fetchedData.length) return;

    const container = $('.additional_services_internet .services_wrapper');

    fetchedData.forEach(element => {
      const itemPrice =
        element.price.indexOf('.') !== -1
          ? element.price.split('.')[1] === '00'
            ? element.price.split('.')[0]
            : element.price
          : element.price;

      const serviceItemMarkup = `<label class="label_item" id='${element.service_name}'>
        <input type="checkbox" name="${element.service_name}" value="1">
        <div class="label_wrap">
          <div class="checkbox_icon"></div>
          <div class="checkbox_image">
            <img src="${element.image}" alt="${element.name}">
          </div>
          <div class="checkbox_title">${element.name}</div>
          <div class="checkbox_text">${element.annotation}</div>
          <div class="checkbox_price">
            <span class="price_wrap">${itemPrice} </span>
            <span>грн/мес</span>
          </div>
        </div>
      </label>`;

      container.append(serviceItemMarkup);
    });
  },

  dateFormat: dateVal => {
    const pad = n => (n < 10 ? '0' : '') + n;

    const newDate = new Date(dateVal);
    const sMonth = pad(newDate.getMonth() + 1);
    const sDay = pad(newDate.getDate());
    const sYear = newDate.getFullYear();

    return `${sYear}-${sMonth}-${sDay}`;
  },

  getTomorrow: () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  },

  removeDataConectFromLS: (data) => {
    const localStorageData = JSON.parse(localStorage.getItem('person'));
    if (localStorageData) {
      localStorageData.dataConect = data || null;
      localStorage.removeItem('person');
      localStorage.setItem('person', JSON.stringify(localStorageData));
    }
  },

  requestPage: async function () {
    if (!$('.request-page').length) {
      return;
    }

    app.removeDataConectFromLS();

    const fetchedDates = await app.fetchData(urls.brigades);

    $(function () {
      $('.jsSearchDataConect').datepicker({
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        minDate: app.getTomorrow(),
        monthNames: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
        dayNamesMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        beforeShowDay: d => [fetchedDates.includes(app.dateFormat(d))],
        onSelect: dateText => getSlots(dateText)
      });
    });

    async function getSlots(date) {
      const container = $('.timesSelect');
      const field = $('.jsSearchTimeConect');
      const fieldInput = $('input[name="timesSelect"]');

      app.removeDataConectFromLS(date);

      container.html('');
      field.text('Загрузка...');
      container.css({ 'opacity': 0, 'pointer-events': 'none' });

      const fetchedSlots = await app.fetchData(`${urls.brigades}/${date}`);
      let availableSlots = [];

      for (const slot in fetchedSlots) {
        if (fetchedSlots[slot] > 0 && fetchedSlots[+slot + 1] > 0 && slot % 2 === 0) {
          availableSlots = [...availableSlots, slot];
        }
      }

      field.text(`с ${availableSlots[0]} до ${+availableSlots[0] + 2}`);
      fieldInput.val(`с ${availableSlots[0]} до ${+availableSlots[0] + 2}`);
      availableSlots.forEach(slot => container.append(`<li>с ${slot} до ${+slot + 2}</li>`));
      container.css({ 'opacity': 1, 'pointer-events': 'auto' });
    }

    const topPage = document.querySelector('.request-page');
    const controls = document.querySelectorAll('.btn_control');
    const noTV = document.querySelector('.no_television');

    controls.forEach(el => {
      el.addEventListener('click', () => {
        checkVal();
      });
    });
    noTV.addEventListener('click', checkVal);
    checkVal();

    const order = {
      totalPrice: 0
    };
    let step = 1;
    $('.request-page').find('.step_next').on('click', function () {
      $('.r-steps-numbers').removeClass(`current-step-${step}`);
      step++;
      changeStep();
      getOrderList();
    });
    $('.request-page').find('.step_prev').on('click', function () {
      $('.r-steps-numbers').removeClass(`current-step-${step}`);
      step--;
      changeStep();
      getOrderList();
    });

    const radioPrivateHouse = document.querySelector('.jsPrivateHouse');
    const radioHighRiseBuilding = document.querySelector('.jsHighRiseBuilding');

    radioHighRiseBuilding.addEventListener('input', checkVal2);
    radioPrivateHouse.addEventListener('input', checkVal2);

    function checkVal2() {
      const InternetRange = document.querySelector('.jsInternetRange');
      const val = InternetRange.querySelector('.content_value').textContent;

      const plusBtn = document.querySelectorAll('.jsSwitchPlus')[1];
      const minusBtn = document.querySelectorAll('.jsSwitchMinus')[1];
      const slider = document.querySelector('.js-irs-1');

      if (val == 30 || noTV.checked) {
        minusBtn.disabled = false;
        minusBtn.style.opacity = 1;

        plusBtn.disabled = false;
        plusBtn.style.opacity = 1;
        slider.style.opacity = 1;
      } else {
        minusBtn.disabled = true;
        minusBtn.style.opacity = 0.5;

        plusBtn.disabled = true;
        plusBtn.style.opacity = 0.5;
        slider.style.opacity = 0.5;
      }
    }

    function checkVal() {
      const InternetRange = document.querySelector('.jsInternetRange');
      const val = InternetRange.querySelector('.content_value').textContent;
      const plusBtn = document.querySelectorAll('.jsSwitchPlus')[1];
      const minusBtn = document.querySelectorAll('.jsSwitchMinus')[1];
      const slider = document.querySelector('.js-irs-1');

      if (val == 30 || noTV.checked) {
        minusBtn.disabled = true;
        minusBtn.style.opacity = 0.5;

        plusBtn.disabled = true;
        plusBtn.style.opacity = 0.5;
      } else {
        minusBtn.disabled = false;
        minusBtn.style.opacity = 1;

        plusBtn.disabled = false;
        plusBtn.style.opacity = 1;
        slider.style.opacity = 1;
      }
    }

    function changeStep() {
      $('.form_item').removeClass('active');
      $(`.r-step-${step}`).addClass('active');
      $('.r-steps-numbers').addClass(`current-step-${step}`);

      step === 4 &&
        $('.card_wrapper').not('.slick-initialized').slick({
          autoplay: false,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        });

      step !== 4 && $('.card_wrapper').hasClass('slick-slider') &&
        $('.card_wrapper').slick('unslick');

      if (step === 2) {
        const now = new Date();
        const nowHours = now.getHours();
        localStorage.setItem('step-2-hours', nowHours);
      } else {
        const step2Hours = +localStorage.getItem('step-2-hours');
        const later = new Date();
        const laterHours = later.getHours();

        if (!step2Hours || laterHours - step2Hours > 1) {
          localStorage.removeItem('step-2-hours');
          step = 2;
          changeStep();
        }
      }
    }

    function getOrderList() {
      if ($('.r-step-2').hasClass('active')) {
        order.houseType =
          $('.house_wrapper input[name="radio_house"]:checked')
            .hasClass('jsHighRiseBuilding') ?
            'Багатоквартирний будинок' :
            'Приватний будинок';

        order.tariff = {
          internetSpeed: +$('.r-step-1 .jsInternetRange').find('.content_value').text(),
          tvChanels: +$('.r-step-1 .jsTVRange').find('.content_value').text(),
          tvPackage: $('.r-step-1 .jsPackage').text(),
          price: +$('.r-step-1 .number.jsPriceCalc').text()
        };

        app.scrollSmooth(topPage);

        const TimeSelect = document.querySelector('.jsSearchTimeConect');
        const formControls = document.querySelector('.r-step-2').querySelectorAll('.form_control');
        const personStor = {};
        const storage = localStorage.getItem('person');
        if (storage) {
          const personStor = JSON.parse(storage);
          $('.form_control.jsSearchSurname').val(personStor.surname);
          $('.form_control.jsSearchMiddlename').val(personStor.middlename);
          $('.form_control.jsSearchName').val(personStor.name);
          $('.form_control.jsSearchDataConect').val(personStor.dataConect);
          TimeSelect.textContent = personStor.timeConect;
        }

        formControls.forEach(input => {
          input.addEventListener('input', function (event) {
            const housDOM = $('.selectric-jsSearchHouse .selectric');
            const key = event.target.name;
            personStor[key] = event.target.value;

            personStor.name = $('.jsSearchName')[0].value;
            personStor.surname = $('.jsSearchSurname')[0].value;
            personStor.middlename = $('.jsSearchMiddlename')[0].value;
            personStor.street = $('.form_control.jsSearchStreet')[0].value;
            personStor.hourse = $('.selectric-jsSearchHouse .selectric').find('.label').text();
            personStor.purch = $('.selectric-jsSearchPurch').find('.label').text();
            personStor.dataConect = $('.hasDatepicker')[0].value;
            personStor.timeConect = TimeSelect.textContent;

            localStorage.removeItem('person');
            localStorage.setItem('person', JSON.stringify(personStor));

            if (input.value.trim().length > 0 && textValid(this)) {
              input.classList.add('success-border');
            } else {
              input.classList.remove('success-border');
            }
            if (housDOM[0].querySelector('.label').textContent !== '' && housDOM[0].querySelector('.label').textContent !== 'Дом') {
              housDOM.addClass('success-border');
            } else {
              housDOM.removeClass('success-border');
            }

            if ($('.jsSearchStreet')[0].value !== '') {
              $('.jsSearchStreet').addClass('success-border');
            } else {
              $('.jsSearchStreet').removeClass('success-border');
            }

            order.person = personStor;
          });

          function textValid(input) {
            if (input.name === 'surname' ||
              input.name === 'name' ||
              input.name === 'middlename') {
              return /^[а-яa-z]+$/i.test(input.value);
            }
            if (input.name === 'apartment') {
              return /^[0-9]+$/i.test(input.value);
            }
            return true;
          }

          document.querySelector('.jsSearchDataConect').addEventListener('blur', function () {
            setTimeout(() => {
              if (this.value !== '') {
                this.classList.add('success-border');
                order.person.dataConect = this.value;
              } else {
                this.classList.remove('success-border');
              }
            }, 100);
          });

          if (input.value !== '') {
            input.classList.add('success-border');
          } else {
            input.classList.remove('success-border');
          }
        });

        if (TimeSelect.textContent === 'Время подключения') {
          TimeSelect.classList.remove('success-border');
        }
        const timeSelect = document.querySelector('.timesSelect');

        TimeSelect.addEventListener('click', () => {
          timeSelect.style.display = 'block';
          order.person.timeConect = this.value;
        });

        timeSelect.addEventListener('click', function (e) {
          const text = e.target.textContent;
          TimeSelect.textContent = text;
          order.person.timeConect = text;

          this.style.display = 'none';
          TimeSelect.classList.add('success-border');
          TimeSelect.style.color = '#5F6061';
          $('input[name="timesSelect"]').val(text);
        });

        const btnNext = $('.step_connection_data');
        const formContainer = $('.connection_data .data_form');

        function chackValidForm() {
          const name = $('.form_control.jsSearchName').hasClass('success-border');
          const midllename = $('.form_control.jsSearchMiddlename').hasClass('success-border');
          const surname = $('.form_control.jsSearchSurname').hasClass('success-border');

          const street = $('.jsSearchStreet').hasClass('success-border');
          const house = $('.jsSearchHouse').hasClass('success-border');
          const apartament = $('.jsSearchApartament').hasClass('success-border');

          const date = $('.jsSearchDataConect').hasClass('success-border');
          const time = $('.jsSearchTimeConect').hasClass('success-border');

          if (name && midllename && surname && street && house && apartament && date && time) {
            btnNext.removeAttr('disabled');
          } else {
            btnNext.attr('disabled', 'disabled');
          }
        }

        formContainer.on('keyup click', chackValidForm);

        btnNext.on('click', function() {
          $(this).attr('disabled', 'disabled');
        });
      }

      if ($('.r-step-3').hasClass('active')) {
        app.scrollSmooth(topPage);

        $('.request-page').find('.r-step-3 .level_item input[type="radio"]')
          .on('click', function () {
            const radioConnect = $(this).siblings('.level_inner');

            order.connection = {
              title: $(radioConnect).find('.item_title').text(),
              subtitle: $(radioConnect).find('.item_sub_title').text(),
              discription: $(radioConnect).find('.item_text').text(),
              price: +$(radioConnect).find('.item_price .price').text()
            };
          });
      }

      if ($('.r-step-4').hasClass('active')) {
        app.scrollSmooth(topPage);

        const equipment = {};
        const btns = $('.r-step-4 .card_item .jsBtnBuyRequesPage');

        btns.on('click', function () {
          const cardTarget = $(this).closest('.card_item');
          cardTarget.toggleClass('active-card');
          const obj = {};
          const key = cardTarget.attr('id');

          const getSummPrice = () => {
            const equipmentPrices = $('.r-step-4 .card_item.active-card');
            let priceEqipment = 0;

            equipmentPrices.each(function () {
              priceEqipment += +$(this).find('.new_price_router').text();
            });

            $('.cost_selected_equipment').text(priceEqipment);
            equipment.totalPrice = priceEqipment;
          };

          if (cardTarget.hasClass('active-card')) {
            const price = +cardTarget.find('.new_price > span.new_price_router').text();
            const title = cardTarget.find('.card_title').text();

            obj.price = price;
            obj.title = title;

            $(this).closest('.card_wrapper').siblings('input.aq_input').val(title);
            $(this).closest('.card_wrapper').siblings('input.aq_input_price').val(price);

            equipment[key] = obj;

            getSummPrice();
          } else {
            equipment[key] = null;

            $(this).closest('.card_wrapper').siblings('input.aq_input').val('');
            $(this).closest('.card_wrapper').siblings('input.aq_input_price').val('');

            getSummPrice();
          }

          order.equipment = equipment;
        });
      }

      if ($('.r-step-5').hasClass('active')) {
        app.scrollSmooth(topPage);

        const items = document.querySelectorAll('.r-step-5 .label_item');
        const additionalServices = {};

        items.forEach(el => {
          el.addEventListener('click', () => {
            const input = el.querySelector('input[type="checkbox"]');
            const key = el.id;
            if (input.checked) {
              const obj = {};
              const price = el.querySelector('.price_wrap').textContent;

              obj.title = el.querySelector('.checkbox_title').textContent;
              obj.price = price;
              additionalServices[key] = obj;
            } else {
              (
                additionalServices[key] = null
              );
            }
            order.additionalServices = additionalServices;

            if (additionalServices) {
              let addServInputString = '';

              for (const key in additionalServices) {
                const element = additionalServices[key];
                element && (addServInputString += `${element.title}; ${element.price}; `);
              }

              $('.additional_services_internet_input').val(addServInputString);
            }
          });
        });
      }

      if ($('.r-step-6').hasClass('active')) {
        app.scrollSmooth(topPage);

        const inputs = document.querySelector('.r-step-6').querySelectorAll('.form_control');
        const contacts = {};
        const phone = document.getElementById('clientphonecodeb');
        const email = document.getElementById('emailcodebe');
        const InpValidCount = {
          phone: false,
          email: false
        };
        inputs.forEach(input => {
          input.addEventListener('keyup', function () {
            if (phone.value.length > 7) {
              phone.classList.remove('error-border');
              phone.classList.add('success-border');
              InpValidCount.phone = true;
            } else {
              InpValidCount.phone = false;
              phone.classList.remove('success-border');
              phone.classList.add('error-border');
            }
            if (app.validateEmail(email.value)) {
              email.classList.remove('error-border');
              email.classList.add('success-border');
              InpValidCount.email = true;
            } else {
              InpValidCount.email = false;
              email.classList.remove('success-border');
              email.classList.add('error-border');
            }

            if (InpValidCount.email && InpValidCount.phone) {
              $('.step_subscriber_data').prop('disabled', false);
            } else {
              $('.step_subscriber_data').prop('disabled', true);
            }

            contacts.phone = phone.value;
            contacts.email = email.value;
            contacts.discription = document.querySelector('.r-step-6.active textarea.form_control ').value;
            order.contacts = contacts;
          });
        });
      }

      if ($('.r-step-7').hasClass('active')) {
        app.scrollSmooth(topPage);

        if (order.person && order.tariff && order.contacts) {
          $('.confirmation_street').text(order.person.street);
          $('.confirmation_house').text(order.person.hourse.slice(0, -3));
          $('.confirmation_apartment').text(order.person.apartment);

          $('.jsDate').text(order.person.dataConect.split('-').reverse().join('-'));
          $('.jsTime').text(order.person.timeConect);

          $('.confirmation_surname').text(order.person.surname);
          $('.confirmation_name').text(order.person.name);
          $('.confirmation_middlename').text(order.person.middlename);

          $('.confirmation_phone').text(order.contacts.phone);
          $('.confirmation_email').text(order.contacts.email);
          $('.confirmation_comments').text(order.contacts.discription);

          $('.confirmation_internet').text(order.tariff.internetSpeed);
          $('.confirmation_tv-plan').text(order.tariff.tvPackage);
          $('.confirmation_tv').text(order.tariff.tvChanels);
          $('.confirmation_internet-price').text(order.tariff.price);

          $('.tariff_total_price').val(order.tariff.price);

          $('.connection_option').text(order.connection.subtitle + ' ' + order.connection.title);
          $('.connection_discription').text(order.connection.discription);
          $('.connection_option_price').text(order.connection.price);

          $('.connection_total_price').val(order.connection.price);
          $('.equipment_total_price').val(order.equipment.totalPrice);

          let lastChangPriceEquip = 0;

          document.querySelectorAll('.JsOutEquipment .td_row').forEach(item => {
            item.addEventListener('click', () => {
              item.classList.toggle('active');
              getNewTotatlPrice();
            });
          });
          let totalPriceOnce;

          function getNewTotatlPrice() {
            lastChangPriceEquip = 0;

            const items = document.querySelectorAll('.JsOutEquipment .td_row.active');
            if (items.length) {
              items.forEach(item => {
                const x = item.querySelector('.options_price span').textContent;
                lastChangPriceEquip += Number(x);
                totalPriceOnce = Number(order.connection.price) + Number(lastChangPriceEquip);
              });
            } else {
              totalPriceOnce = Number(order.connection.price);
            }
            document.querySelector('.title_summ_number_once').textContent = totalPriceOnce;
            const totalPriceMon = Number(document.querySelector('.monthly_payment').textContent);

            document.querySelector('.summ_number').textContent = totalPriceOnce + totalPriceMon;
          }

          let additionalBlock = '';
          const additionalItems = order.additionalServices;
          let totalPriceAdditionalService = 0;

          for (const key in additionalItems) {
            if (additionalItems[key]) {
              const element = additionalItems[key];
              let grn;
              let color;

              element.price == 'Бесплатно' ? grn = '' : grn = 'грн/мес';
              element.price == 'Бесплатно' ? null : totalPriceAdditionalService += Number(element.price);
              element.price == 'Бесплатно' ? color = 'style="color:#DC662D"' : color = '';

              additionalBlock += `
                <div class="td_row ">

                  <div class="options_title">${element.title} </div>
                  <div class="options_price " ${color}>${element.price} ${grn}</div>
                </div>
              `;
            }
          }

          $('.additional_services_total_price').val(totalPriceAdditionalService);

          document.querySelector('.additional_options').innerHTML = additionalBlock;

          const totalPriceMonthly = Number(order.tariff.price) + Number(totalPriceAdditionalService);
          document.querySelector('.monthly_payment').textContent = totalPriceMonthly;

          getNewTotatlPrice();
        }
      }
    }
  },

  login2fa: function () {
    if (!$('.auth-wrapper #login_account_2fa').length) {
      return;
    }

    let number;
    const timer = 60;

    $('.auth-wrapper #login_account_2fa').find('select').on('change', function (e) {
      number = e.target.value;

      if (number !== '') {
        $('.auth-wrapper #login_account_2fa .btn').attr('disabled', false);
      } else {
        $('.auth-wrapper #login_account_2fa .btn').attr('disabled', true);
      }
    });

    $('.auth-wrapper #login_account_2fa').find('form').on('submit', function (e) {
      e.preventDefault();

      $('.auth-wrapper #login_account_2fa').hide();
      $('.auth-wrapper #login_code_2fa').show();

      $('.auth-wrapper #login_code_2fa').find('.date-time').empty().append(formatDate(new Date()));
      $('.auth-wrapper #login_code_2fa').find('.tel-number').empty().append(cersuredNumber(number));
      $('.auth-wrapper #login_code_2fa').find('.sec').empty().append(timer);

      countDown(timer);
    });

    $('.auth-wrapper #login_code_2fa').find('.resend button').on('click', function (e) {
      e.preventDefault();

      $('.auth-wrapper #login_code_2fa').find('.resend').hide();
      $('.auth-wrapper #login_code_2fa').find('.resend_info').show();
      $('.auth-wrapper #login_code_2fa').find('.sec').empty().append(timer);

      countDown(timer);
    });

    $('.auth-wrapper #login_code_2fa').find('input[type="text"]').on('keyup', function (e) {
      if (e.target.value !== '') {
        $('.auth-wrapper #login_code_2fa input[type="submit"]').attr('disabled', false);
      } else {
        $('.auth-wrapper #login_code_2fa input[type="submit"]').attr('disabled', true);
      }
    });

    function countDown(timer) {
      const interval = setInterval(() => {
        if (timer > 0) {
          timer = --timer;
          $('.auth-wrapper #login_code_2fa').find('.sec').empty().append(timer);
        } else {
          $('.auth-wrapper #login_code_2fa').find('.resend_info').hide();
          $('.auth-wrapper #login_code_2fa').find('.resend').show();
          clearInterval(interval);
        }
      }, 1000);
    }

    function cersuredNumber(number) {
      const start = number.slice(0, 10);
      const finish = number.slice(-3);
      return `${start}XXX-XX${finish}`;
    }

    function formatDate(date) {
      let dd = date.getDay();
      if (dd < 10) {
        dd = '0' + dd;
      }

      let mm = date.getMonth() + 1;
      if (mm < 10) {
        mm = '0' + mm;
      }

      const yyyy = date.getFullYear();

      let hh = date.getHours();
      if (hh < 10) {
        hh = '0' + hh;
      }

      let minutes = date.getMinutes() + 1;
      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return `${dd}.${mm}.${yyyy} ${hh}:${minutes}`;
    }
  },

  newPassword: function () {
    if (!$('.auth-wrapper #new-password').length) {
      return;
    }

    let newPass;
    let confirmPass;

    $('.auth-wrapper #new-password input.new_pass').on('keyup', function (e) {
      newPass = e.target.value;
    });

    $('.auth-wrapper #new-password input.confirm_pass').on('keyup', function (e) {
      confirmPass = e.target.value;

      if (newPass !== '' && confirmPass !== '' && newPass === confirmPass) {
        $('.auth-wrapper #new-password .btn').attr('disabled', false);
      } else {
        $('.auth-wrapper #new-password .btn').attr('disabled', true);
      }
    });

    $('.auth-wrapper #new-password form').on('submit', function (e) {
      e.preventDefault();

      $('.auth-wrapper #new-password').hide();

      $('.auth-wrapper .message.success').show();
      redirectFromMesage(true);
    });

    function redirectFromMesage(isSuccess) {
      setTimeout(() => {
        if (!isSuccess) {
          $('.auth-wrapper .message').hide();
          $('.auth-wrapper #new-password').show();
        }
      }, 3000);
    }
  },

  savePassword: function () {
    const areaOne = document.querySelectorAll('.form_control')[0];
    const areaSecond = document.querySelectorAll('.form_control')[1];
    let valid;
    const btnSave = document.getElementById('btn-password-save');
    if (btnSave) {
      areaOne.addEventListener('change', () => {
        valid = checkPasswords(areaOne);
        if (valid) {
          areaSecond.addEventListener('keyup', () => {
            btnDisabled(valid);
          });
        }
        btnDisabled();
      });
    }

    function btnDisabled(valid) {
      if (valid) {
        btnSave.disabled = false;
      } else {
        btnSave.disabled = true;
      }
    }

    if (btnSave) {
      btnSave.onclick = function (e) {
        e.preventDefault();
        if (areaOne.value !== areaSecond.value) {
          const helpText = document.querySelector('.password_help');
          helpText.innerHTML = 'пароли не совпадают.';
          helpText.style.display = 'block';
          areaOne.classList.add('error-border');
          areaSecond.classList.add('error-border');
          setTimeout(() => {
            helpText.style.display = 'none';
            helpText.innerHTML = 'слишком слабый пароль.';
            areaOne.classList.remove('error-border');
            areaSecond.classList.remove('error-border');
          }, 3000);
        }
        e.stopPropagation();
      };
    }

    function checkPasswords(inp) {
      const password = inp.value;
      if (password == '') {
        return;
      }
      const s_letters = 'qwertyuiopasdfghjklzxcvbnm';
      const b_letters = 'QWERTYUIOPLKJHGFDSAZXCVBNM';
      const digits = '0123456789';
      let is_s = false;
      let is_b = false;
      let is_d = false;

      let result = false;
      for (let i = 0; i < password.length; i++) {
        if (!is_s && s_letters.indexOf(password[i]) != -1) {
          is_s = true;
        } else if (!is_b && b_letters.indexOf(password[i]) != -1) {
          is_b = true;
        } else if (!is_d && digits.indexOf(password[i]) != -1) {
          is_d = true;
        }
      }
      let rating = 0;
      if (is_s) {
        rating++;
      }

      if (is_b) {
        rating++;
      }

      if (is_d) {
        rating++;
      }

      if (password.length > 7 && rating > 2) {
        result = true;
        return result;
      } else {
        document.querySelector('.password_help').style.display = 'block';
        areaOne.classList.toggle('error-border');
        setTimeout(() => {
          document.querySelector('.password_help').style.display = 'none';
          areaOne.classList.toggle('error-border');
        }, 3000);
      }

      return result;
    }
  },

  forgotPassword: function () {
    if (!$('.auth-wrapper #forgot-password').length) {
      return;
    }

    $('.auth-wrapper #forgot-password-phone input.form_control').on('keyup', function (e) {
      const val = e.target.value;
      const valNumber = +val.replace(/\D+/g, '');

      if (JSON.stringify(valNumber).length === 12) {
        $('.auth-wrapper #forgot-password [name="forgot-password-phone"]').attr('disabled', false);
      } else {
        $('.auth-wrapper #forgot-password [name="forgot-password-phone"]').attr('disabled', true);
      }
    });

    $('.auth-wrapper #forgot-password-email input.form_control').on('keyup', function (e) {
      const pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{1,9}\.)?[a-z]{1,7}$/i;
      const val = e.target.value;

      if (pattern.test(val)) {
        $('.auth-wrapper #forgot-password [name="forgot-password-email"]').attr('disabled', false);
      } else {
        $('.auth-wrapper #forgot-password [name="forgot-password-email"]').attr('disabled', true);
      }
    });

    const form = document.getElementById('forgot-password');
    const method_phone = form.querySelector('.method_item_phone');
    const method_email = form.querySelector('.method_item_email');
    const btn_phone = document.getElementById('forgot-password-phone');
    const btn_email = document.getElementById('forgot-password-email');

    method_phone.addEventListener('click', () => {
      method_phone.classList.add('active');
      method_email.classList.remove('active');
      btn_email.style.display = 'none';
      btn_phone.style.display = 'block';
    });

    method_email.addEventListener('click', () => {
      method_email.classList.add('active');
      method_phone.classList.remove('active');
      btn_phone.style.display = 'none';
      btn_email.style.display = 'block';
    });
  },

  loginVariations: function () {
    if (!$('.auth-wrapper #login_account_email').length ||
      !$('.auth-wrapper #login_account_phone').length) {
      return;
    }

    let loginVariantState = 'email';
    let confirmStatePhone = false;
    let confirmStateEmail = false;
    let email;
    let phone;
    let passwordEmail;
    let passwordPhone;

    $('.auth-wrapper').find('.method_item').on('click', function () {
      if ($(this).hasClass('method_item_email')) {
        $('#login_account_phone').hide();
        $('#login_account_email').show();
        loginVariantState = 'email';
      } else if ($(this).hasClass('method_item_phone')) {
        $('#login_account_email').hide();
        $('#login_account_phone').show();
        loginVariantState = 'phone';
      }

      enterBtnDisableToggle();
    });

    $('.auth-wrapper #login_account_phone').find('.confirm button').on('click', function () {
      $('.auth-wrapper #login_account_phone').find('.confirm').hide();
      $('.auth-wrapper #login_account_phone').find('.confirmed').show();
      confirmStatePhone = true;

      enterBtnDisableToggle();
    });

    $('.auth-wrapper #login_account_email').find('.confirm button').on('click', function () {
      $('.auth-wrapper #login_account_email').find('.confirm').hide();
      $('.auth-wrapper #login_account_email').find('.confirmed').show();
      confirmStateEmail = true;

      enterBtnDisableToggle();
    });

    $('.auth-wrapper #login_account_email .email input').on('keyup', function (e) {
      email = e.target.value;

      enterBtnDisableToggle();
    });
    $('.auth-wrapper #login_account_phone .phone input').on('keyup', function (e) {
      phone = e.target.value;

      enterBtnDisableToggle();
    });
    $('.auth-wrapper #login_account_email input[type="password"]').on('keyup', function (e) {
      passwordEmail = e.target.value;

      enterBtnDisableToggle();
    });
    $('.auth-wrapper #login_account_phone input[type="password"]').on('keyup', function (e) {
      passwordPhone = e.target.value;

      enterBtnDisableToggle();
    });

    function enterBtnDisableToggle() {
      if (loginVariantState === 'email') {
        if (email !== '' && validateEmail(email) && passwordEmail !== '' && confirmStateEmail) {
          $('.auth-wrapper #login_account_email input[type="submit"]').attr('disabled', false);
        } else {
          $('.auth-wrapper #login_account_email input[type="submit"]').attr('disabled', true);
        }
      } else if (loginVariantState === 'phone') {
        if (phone !== '' && passwordPhone !== '' && confirmStatePhone) {
          $('.auth-wrapper #login_account_phone input[type="submit"]').attr('disabled', false);
        } else {
          $('.auth-wrapper #login_account_phone input[type="submit"]').attr('disabled', true);
        }
      }
    }

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },

  tariffMechanicsInfoExpander: function () {
    if (!$('.tariff_mechanics_info').length) {
      return;
    }

    $('.tariff_mechanics_info').find('li').on('click', function () {
      if ($(this).hasClass('expanded')) {
        $(this).removeClass('expanded');
        return;
      }

      $('.tariff_mechanics_info').find('li').removeClass('expanded');
      $(this).addClass('expanded');
    });
  },

  windowResize: function () {
    $(window).on('resize', function () {
    });
  },

  menu: function () {
    $('.btn_services').on('click', function () {
      $(this)
        .next('.services_list')
        .slideToggle(300);
    });

    $(document).on('click', function (e) {
      const $btn_services = $('.btn_services');
      const $services_list = $('.services_list');
      if (!$btn_services.is(e.target) && $btn_services.has(e.target).length === 0 && !$services_list.is(e.target) && $services_list.has(e.target).length === 0) {
        $('.services_list').slideUp(300);
      }
    });
  },

  sliders: function () {
    $('.jsSpecBanner').not('.slick-initialized').slick({
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000
    });

    const $jsDescription = $('.jsDescription');
    $jsDescription.on('init', function () {
      $jsDescription.find('.slick-current').addClass('slide_active');
    });
    $jsDescription.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      responsive: [{
        breakpoint: 767,
        settings: {
          adaptiveHeight: true,
          dots: false,
          arrows: false
        }
      }]
    });
    $jsDescription.on('beforeChange', function () {
      $jsDescription.find('.slick-current').removeClass('slide_active');
    });
    $jsDescription.on('afterChange', function () {
      $jsDescription.find('.slick-current').addClass('slide_active');
    });

    const dotsHeight = $jsDescription.find('.slick-dots').innerHeight() + 25;
    $jsDescription.find('.slick-next').css('margin-top', '-' + dotsHeight + 'px');
    $jsDescription.find('.slick-prev').css('margin-bottom', '-' + dotsHeight + 'px');

    function getSliderSettings() {
      return {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
      };
    }

    function checkFilter() {
      if ($('.jsPopularSlider').hasClass('slick-initialized')) {
        $('.jsPopularSlider').slick('unslick');
      }
      $('.jsPopularSlider .slider_item').appendTo('.popular_slides');

      let item;

      if ($('input[name="rates"]:checked').val() == 'internet' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"][data-rates="internet"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internet' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"][data-rates="internet"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internet' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"][data-rates="internet"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internet' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"][data-rates="internet"]');
        item.appendTo('.jsPopularSlider');
      }

      if ($('input[name="rates"]:checked').val() == 'internettv' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"][data-rates="internettv"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internettv' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"][data-rates="internettv"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internettv' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"][data-rates="internettv"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'internettv' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"][data-rates="internettv"]');
        item.appendTo('.jsPopularSlider');
      }

      if ($('input[name="rates"]:checked').val() == 'all' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'all' && !$('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="highBuildings"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'all' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"]');
        item.appendTo('.jsPopularSlider');
      }
      if ($('input[name="rates"]:checked').val() == 'all' && $('input[name="hous"]').prop('checked')) {
        item = $('.popular_slides .slider_item[data-hous="privateHouses"]');
        item.appendTo('.jsPopularSlider');
      }
    }

    $(window).on('load', function () {
      checkFilter();
      $('.jsPopularSlider').not('.slick-initialized').slick(getSliderSettings());

      if (!$('.b_popular_rates input[name="discounts"]').prop('checked')) {
        $('.b_popular_rates .slider_item[data-discounts="year"]').each(function () {
          const $this = $(this);
          const singlePrice = $this.attr('data-singlePrice');
          $this.find('.price_number').text(singlePrice);
          $this.removeClass('discount');
        });
      }
      if ($('.b_popular_rates input[name="discounts"]').prop('checked')) {
        $('.b_popular_rates .slider_item[data-discounts="year"]').each(function () {
          const $this = $(this);
          const newPrice = $this.attr('data-newPrice');
          $this.find('.price_number').text(newPrice);
          $this.addClass('discount');
        });
      }
    });

    $('.b_popular_rates .slider_item[data-discounts="year"]').each(function () {
      const $this = $(this);
      const price = parseFloat($this.find('.price_number').text());
      const new_price = parseFloat((price * 0.1));

      $this.attr('data-singlePrice', price);

      let needPrice = 0;
      needPrice = Math.round(price - new_price);
      $this.attr('data-newPrice', needPrice);

      const discount = Math.round(new_price * 12);
      $this.find('.jsDiscount').text(discount);
    });

    $('.b_popular_rates input[name="rates"]').on('change', function () {
      checkFilter();
      $('.jsPopularSlider').not('.slick-initialized').slick(getSliderSettings());
    });

    $('.b_popular_rates input[name="hous"]').on('change', function () {
      checkFilter();
      $('.jsPopularSlider').not('.slick-initialized').slick(getSliderSettings());
    });

    $('.b_popular_rates input[name="discounts"]').on('change', function () {
      if (!$(this).prop('checked')) {
        $('.b_popular_rates .slider_item[data-discounts="year"]').each(function () {
          const $this = $(this);
          const singlePrice = $this.attr('data-singlePrice');
          $this.find('.price_number').text(singlePrice);
          $this.removeClass('discount');
        });
      }
      if ($(this).prop('checked')) {
        $('.b_popular_rates .slider_item[data-discounts="year"]').each(function () {
          const $this = $(this);
          const newPrice = $this.attr('data-newPrice');
          $this.find('.price_number').text(newPrice);
          $this.addClass('discount');
        });
      }
    });

    $('.jsBrandsSlider').each(function () {
      $(this).not('.slick-initialized').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2.5
          }
        }
        ]
      });
    });

    $(window).on('load resize orientationchange', function () {
      $('.jsChannelsWrapperMegogo').each(function () {
        const $carousel = $(this);

        if ($(window).width() > 767) {
          if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick');
          }
        } else {
          if (!$carousel.hasClass('slick-initialized')) {
            $carousel.slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true
            });
          }
        }
      });
    });

    const slideCurrent = document.querySelector('.slick-current');
    const arrows = document.querySelectorAll('.slick-arrow');

    if (slideCurrent) {
      arrows.forEach(item => {
        item.addEventListener('click', function () {
          setTimeout(() => {
            activeBtn();
          }, 300);
        });
      });
    }

    function activeBtn() {
      const slideActive = document.querySelector('.jsChannelsWrapper .slick-slide.slick-current.slick-active');
      if (slideActive) {
        slideActive.querySelector('[name="subscription"]').checked = true;
      }
    }

    if (window.innerWidth < 767) {
      activeBtn();
    }
  },

  allscript: function () {
    setTimeout(function () {
      $('.heared_info').slideUp(300);
    }, 6000);

    $('.b_menu_stuck').sticky({
      topSpacing: 0
    });

    $('.jsMoreLess').on('click', function (e) {
      $(this).parents('.equipment_content').find('.equipment_characteristics').slideToggle(300);
      e.preventDefault();
      $(this).toggleClass('active');
      const textMore = $(this).attr('data-more');
      const textLess = $(this).attr('data-less');
      if ($(this).hasClass('active')) {
        $(this).text(textLess);
      } else {
        $(this).text(textMore);
      }
    });
  },

  selectric: function () {
    $('.jsSelectric').selectric({
      maxHeight: 200,
      disableOnMobile: false,
      nativeOnMobile: false
    });
  },

  rangeSingle: async function () {
    let internetArrayByType = [];
    const fetchedData = await app.fetchData(urls.tariffs);
    let isNoTV = false;
    let tvArraysBySpeed;
    let currentTvPacket = null;
    let quantityMovies = 0;
    const speedsSet = new Set();
    const speedsWithTvSet = new Set();
    const speedsWithoutTvSet = new Set();
    const channelsSet = new Set();
    const megogoSet = new Set();
    const filmsSet = new Set();

    fetchedData.forEach(rate => {
      speedsSet.add(rate.speed);
      channelsSet.add(rate.channel);
      megogoSet.add(rate.values_tv_chanels_name);
      filmsSet.add(rate.category.movies);
    });
    const megogoArr = [...(megogoSet)];

    const activeRate = {
      speed: fetchedData[0]?.speed,
      channelCount: fetchedData[0].channel,
      noTV: false,
      packageName: 'Оптимальный'
    };
    let values_tv = [];
    let firstInternetSpeed;
    let defaultCalculatorData;

    function getSlideTVValues() {
      return tvArraysBySpeed.map((item, i) => i + 1);
    }

    function getQuantityMovies(speed, channels) {
      let quantityMovies = 0;
      let currentSpeed = 0;
      speed === '1000' ? currentSpeed = 1000 : currentSpeed = speed;
      fetchedData.map(item => (item.speed == currentSpeed && item.channel == channels) ? quantityMovies = item.category.movies : false);
      return quantityMovies;
    }
    function setActivePackageName(speed, channel) {
      let currentSpeed = 0;
      speed === '1000' ? currentSpeed = 1000 : currentSpeed = speed;
      const packageName = internetArrayByType.filter(item => item.speed == currentSpeed && item.channel == channel)[0].category.name;
      return packageName ? packageName : 'Оптимальный';
    }

    $('.jsRequestWrapper').each(function () {
      const $thisForm = $(this);
      const $InternetRange = $thisForm.find('.jsInternetRange');
      const $TVRange = $thisForm.find('.jsTVRange');
      const $noTV = $thisForm.find('input.no_television');
      const $internetValue = $thisForm.find('.internet_value');
      const $PriceCalc = $thisForm.find('.jsPriceCalc');
      const $PriceCalcOld = $thisForm.find('.jsPriceCalcOld');
      const $TvValue = $thisForm.find('.jsTvValue');
      const $package = $thisForm.find('.jsPackage');
      const $films = $thisForm.find('.jsFilmsValue');
      const PrivateHouse = $thisForm.find('.jsPrivateHouse');
      const HighRiseBuilding = $thisForm.find('.jsHighRiseBuilding');
      const connection_type_HighRiseBuilding = $thisForm.find('.connection_type_HighRiseBuilding');
      const connection_type_PrivateHouse = $thisForm.find('.connection_type_PrivateHouse');

      const $TvRate = $thisForm.find('input[name="TvRate"]');
      const $InternetRate = $thisForm.find('input[name="InternetRate"]');

      let interner_modal = 0;

      let rightRate;

      $InternetRange.each(function () {
        const $this = $(this);
        const $thisminus = $this.find('.jsSwitchMinus');
        const $thisplus = $this.find('.jsSwitchPlus');
        let checked;
        let checked_name;
        const $range = $this.find('.jsRangeSlider');
        let min = 0;
        let from = 0;
        const step = 1;

        let values_internet_name; let values_internet; let custom_values; let max;
        let sortedSpeeds = [];
        const internetTariffs = {
          highRiseBuilding: {},
          privatHouse: {}
        };

        fetchedData.forEach(rate => {
          if (rate.type === 'highBuildings') {
            if (!internetTariffs.highRiseBuilding[rate.category.slug]) {
              internetTariffs.highRiseBuilding[rate.category.slug] = [];
            }
            internetTariffs.highRiseBuilding[rate.category.slug].push([rate.speed]);
          }

          if (rate.type === 'privateHouses') {
            if (!internetTariffs.privatHouse[rate.category.slug]) {
              internetTariffs.privatHouse[rate.category.slug] = [];
            }

            internetTariffs.privatHouse[rate.category.slug].push([rate.speed]);
          }
        });

        function getInternetSpeed(speedSet) {
          function sortNumber(a, b) {
            return a - b;
          }

          const arr = [...speedSet].sort(sortNumber);

          return arr.map(item => {
            let internetSpeed = `${item}`;

            item == 1000 ? internetSpeed = '1000' : null;
            return internetSpeed;
          });
        }

        function getSlideValues() {
          return sortedSpeeds.map((item, i) => i + 1);
        }

        function getInternetNames(type) {
          function sortNumber(a, b) {
            return a - b;
          }

          const set = new Set();

          for (const key in internetTariffs[type]) {
            internetTariffs[type][key].forEach(item => set.add(item[0]));
          }
          const arr = [...set].sort(sortNumber);
          return arr.map(item => {
            let internetSpeed = `${item}`;

            item == 1000 ? internetSpeed = '1000' : null;
            return internetSpeed;
          });
        }

        function getCustomValues(type) {
          return getInternetNames(type).map((item, i) => i + 1);
        }

        function getInternetObjects(type) {
          speedsWithoutTvSet.clear();
          speedsWithTvSet.clear();
          const intArray = [];
          fetchedData.map(item => item.type === type ? intArray.push(item) : false);
          fetchedData.forEach(rate => {
            if (rate.type === type) {
              rate.channel > 0 ? speedsWithTvSet.add(rate.speed) : speedsWithoutTvSet.add(rate.speed);
            }
          });
          return intArray;
        }

        function setActiveChannelCount(speed) {
          let internetSpeed;
          speed === '1000' ? internetSpeed = 1000 : internetSpeed = speed;
          if (!$noTV.prop('checked')) {
            for (let i = 0; i < internetArrayByType.length; i++) {
              if (internetArrayByType[i].channel > 0 && fetchedData[i].speed == internetSpeed) {
                activeRate.channelCount = internetArrayByType[i].channel;
                break;
              }
            }
          }
        }

        if ($('.highBuildings').prop('checked')) {
          internetArrayByType = getInternetObjects('highBuildings');
          sortedSpeeds = getInternetSpeed(speedsWithTvSet);
          activeRate.speed = sortedSpeeds[0];
          setActiveChannelCount(activeRate.speed);
        }
        if ($('.privateHouses').prop('checked')) {
          internetArrayByType = getInternetObjects('privateHouses');
          sortedSpeeds = getInternetSpeed(speedsWithTvSet);
          activeRate.speed = sortedSpeeds[0];
          setActiveChannelCount(activeRate.speed);
        }

        if ($('.highBuildings-internet').prop('checked')) {
          internetArrayByType = getInternetObjects('highBuildings');
          sortedSpeeds = getInternetSpeed(speedsWithoutTvSet);
          activeRate.speed = sortedSpeeds[0];
          $noTV.prop('checked', true);
        }

        firstInternetSpeed = [...sortedSpeeds.values()][0];
        defaultCalculatorData = internetArrayByType.find(item => (item.speed == firstInternetSpeed && item.channel > 0));
        defaultCalculatorData === undefined ? defaultCalculatorData = internetArrayByType.find(item => item.speed == firstInternetSpeed) : false;
        defaultCalculatorData !== undefined ? $this.find('.content_value').text(defaultCalculatorData.speed) : false;
        defaultCalculatorData !== undefined ? $internetValue.text(defaultCalculatorData.speed) : false;
        defaultCalculatorData !== undefined ? $package.text(defaultCalculatorData.category.name) : false;

        if (!isNoTV) {
          tvArraysBySpeed = getValuesForTV(50);
        }

        if (HighRiseBuilding.prop('checked')) {
          values_internet_name = getInternetNames('highRiseBuilding');
          values_internet = getInternetNames('highRiseBuilding');
          custom_values = getCustomValues('highRiseBuilding');
          max = custom_values.length - 1;
        }

        if (PrivateHouse.prop('checked')) {
          values_internet_name = getInternetNames('privatHouse');
          values_internet = getInternetNames('privatHouse');
          custom_values = getCustomValues('privatHouse');
          max = custom_values.length - 1;
        }

        if (!HighRiseBuilding.length && !PrivateHouse.length) {
          values_internet_name = getInternetNames('highRiseBuilding');
          values_internet = getInternetNames('highRiseBuilding');
          custom_values = getSlideValues();
          max = custom_values.length - 1;
        }

        $range.ionRangeSlider({
          min: 0,
          from: 0,
          step: 1,
          skin: 'round',
          grid: false,
          hide_min_max: true,
          hide_from_to: true,
          max: max,
          values: getSlideValues()
        });
        const range_instance = $range.data('ionRangeSlider');

        $('.house_radio input[type="radio"]').on('change', function () {
          if ($(this).hasClass('jsPrivateHouse')) {
            HighRiseBuilding.prop('checked', false);
            PrivateHouse.prop('checked', true);
          } else {
            HighRiseBuilding.prop('checked', true);
            PrivateHouse.prop('checked', false);
          }
          if (HighRiseBuilding.prop('checked')) {
            internetArrayByType = getInternetObjects('highBuildings');
            values_internet_name = getInternetNames('highRiseBuilding');
            values_internet = getInternetNames('highRiseBuilding');
            custom_values = getSlideValues();
            max = custom_values.length - 1;
            range_instance.update({
              values: custom_values,
              min: 0,
              max: max,
              from: 0
            });
          }

          if (PrivateHouse.prop('checked')) {
            internetArrayByType = getInternetObjects('privateHouses');
            connection_type_PrivateHouse.show();
            connection_type_HighRiseBuilding.hide();
            values_internet_name = getInternetNames('privatHouse');
            values_internet = getInternetNames('privatHouse');
            custom_values = getCustomValues('privatHouse');
            max = custom_values.length - 1;
            range_instance.update({
              values: custom_values,
              min: 0,
              max: max,
              from: 0
            });
          }
          defaultCalculatorData = internetArrayByType.find(item => (item.speed == internetArrayByType[0].speed && item.channel > 0));
          if (defaultCalculatorData !== undefined) {
            $internetValue.text(defaultCalculatorData.speed);
            $this.find('.content_value').text(defaultCalculatorData.channel);
            $TvValue.text(defaultCalculatorData.channel);
            $PriceCalc.text(defaultCalculatorData.price);
            $films.text(defaultCalculatorData.category.movies);
            $package.text(defaultCalculatorData.category.name);
            activeRate.speed = defaultCalculatorData.speed;
            activeRate.channelCount = defaultCalculatorData.channel;
            if (!isNoTV) {
              tvArraysBySpeed = getValuesForTV(defaultCalculatorData.speed);
            }
          }

          if (HighRiseBuilding.prop('checked')) {
            connection_type_HighRiseBuilding.show();
            connection_type_PrivateHouse.hide();

            if ($noTV.prop('checked')) {
              custom_values = getSlideValues();
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: custom_values.length - 1
              });
              min = 0;
              interner_modal = 0;
            } else {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: custom_values.length - 1
              });
              min = 0;
              interner_modal = 0;
            }
            checked = values_internet[$range.prop('value') - 1];
            $this.find('.content_value').text(checked);
          }

          if (PrivateHouse.prop('checked')) {
            if ($noTV.prop('checked')) {
              custom_values = getSlideValues();
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: custom_values.length - 1
              });
              min = 0;
              interner_modal = 0;
            } else {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: custom_values.length - 1
              });
              min = 0;
              interner_modal = 0;
            }

            checked = values_internet[$range.prop('value') - 1];
            $this.find('.content_value').text(checked);
          }

          checkprice_modal();
        });

        const updateRange = function (direction) {
          from += step * direction;
          if (from <= min) {
            from = min;
          } else if (from >= max) {
            from = max;
          }
          range_instance.update({
            from: from
          });
        };

        function getValuesForTV(internetSpeed) {
          const tvValues = [];
          let speed;
          internetSpeed === '1000' ? speed = 1000 : speed = internetSpeed;
          internetArrayByType.map(item => (item.speed == speed && item.channel > 0) ? tvValues.push(item.channel) : false);
          return tvValues;
        }

        tvArraysBySpeed = getValuesForTV(sortedSpeeds[0]);
        values_tv = getSlideTVValues();

        $noTV.on('change', function () {
          isNoTV = !isNoTV;
          isNoTV ? sortedSpeeds = getInternetSpeed(speedsWithoutTvSet) : sortedSpeeds = getInternetSpeed(speedsWithTvSet);
          custom_values = getSlideValues();
          max = custom_values.length - 1;
          $range.data('ionRangeSlider').update({
            values: getSlideValues()
          });
          if (HighRiseBuilding.prop('checked')) {
            if ($noTV.prop('checked')) {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: max
              });
              min = 0;
              interner_modal = 0;
            } else {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: max
              });
              min = 0;
              interner_modal = 0;
            }
          }

          if (PrivateHouse.prop('checked')) {
            if ($noTV.prop('checked')) {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: max
              });
              min = 0;
              interner_modal = 0;
            } else {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0,
                max: max
              });
              min = 0;
              interner_modal = 0;
            }
          }

          if (!HighRiseBuilding.length && !PrivateHouse.length) {
            if ($noTV.prop('checked')) {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0
              });
              min = 0;
              interner_modal = 0;
            } else {
              $range.data('ionRangeSlider').update({
                min: 0,
                from: 0,
                from_min: 0
              });
              min = 0;
              interner_modal = 0;
            }
          }
        });

        $range.on('change', function () {
          from = $range.prop('value') - 1;
          checked = values_internet[$range.prop('value') - 1];
          checked_name = values_internet_name[$range.prop('value') - 1];
          $this.find('.content_value').text(checked);
          activeRate.speed = checked;

          if (!isNoTV) {
            tvArraysBySpeed = getValuesForTV(checked_name);
          }

          values_tv = getSlideTVValues();
          currentTvPacket === null ? currentTvPacket = tvArraysBySpeed[0] : false;
          quantityMovies = getQuantityMovies(checked_name, tvArraysBySpeed[0]);
          if (checked_name === '1000') {
            const change = 'Гігабітний до 1000';
            $internetValue.text(change);
          } else {
            $internetValue.text(checked_name);
          }
          $InternetRate.val(checked_name);

          interner_modal = from;
          checkprice_modal();
          $TVRange.each(function () {
            const $this = $(this);
            const $range = $this.find('.jsRangeSlider');
            const range_instance = $range.data('ionRangeSlider');
            range_instance.update({
              from: 0,
              values: values_tv
            });
          });
        });

        $thisminus.on('click', function () {
          updateRange(-1);
          checked = values_internet[$range.prop('value') - 1];
          checked_name = values_internet_name[$range.prop('value') - 1];
          $this.find('.content_value').text(checked);
          activeRate.speed = checked;
          setActiveChannelCount(activeRate.speed);
          if (checked_name === '1000') {
            const change = 'Гігабітний до 1000';
            $internetValue.text(change);
          } else {
            $internetValue.text(checked_name);
          }
          $InternetRate.val(checked_name);
          !isNoTV ? $package.text(setActivePackageName(activeRate.speed, activeRate.channelCount)) : false;
          interner_modal = from;
          checkprice_modal();
          values_tv.length <= 1 ? document.querySelectorAll('.jsSwitchMinus')[1].disabled = true : document.querySelectorAll('.jsSwitchMinus')[1].disabled = false;
          values_tv.length <= 1 ? document.querySelectorAll('.jsSwitchPlus')[1].disabled = true : document.querySelectorAll('.jsSwitchPlus')[1].disabled = false;
        });
        $thisplus.on('click', function () {
          updateRange(1);
          checked = values_internet[$range.prop('value') - 1];
          checked_name = values_internet_name[$range.prop('value') - 1];
          $this.find('.content_value').text(checked);
          activeRate.speed = checked;
          setActiveChannelCount(activeRate.speed);
          if (checked_name === '1000') {
            const change = 'Гігабітний до 1000';
            $internetValue.text(change);
          } else {
            $internetValue.text(checked_name);
          }
          $InternetRate.val(checked_name);
          !isNoTV ? $package.text(setActivePackageName(activeRate.speed, activeRate.channelCount)) : false;
          interner_modal = from;
          checkprice_modal();
          values_tv.length <= 1 ? document.querySelectorAll('.jsSwitchMinus')[1].disabled = true : document.querySelectorAll('.jsSwitchMinus')[1].disabled = false;
          values_tv.length <= 1 ? document.querySelectorAll('.jsSwitchPlus')[1].disabled = true : document.querySelectorAll('.jsSwitchPlus')[1].disabled = false;
        });
      });

      $TVRange.each(function () {
        const $this = $(this);
        const $thisminus = $this.find('.jsSwitchMinus');
        const $thisplus = $this.find('.jsSwitchPlus');
        let checked;
        let checked_package;
        const $range = $this.find('.jsRangeSlider');
        const min = 0;
        let max = 2;
        let from = 0;
        const step = 1;
        const values_tv_chanels_name = megogoArr;

        function setDefaultDataTV() {
          if (defaultCalculatorData !== undefined && !isNoTV) {
            $this.find('.content_value').text(defaultCalculatorData.channel);
            $TvValue.text(defaultCalculatorData.channel);
            $films.text(defaultCalculatorData.category.movies);
          }
        }
        setDefaultDataTV();

        $range.ionRangeSlider({
          skin: 'round',
          grid: false,
          hide_min_max: true,
          hide_from_to: true,
          values: values_tv
        });

        const range_instance = $range.data('ionRangeSlider');
        const updateRange = function (direction) {
          from += step * direction;
          if (from <= min) {
            from = min;
          } else if (from >= max) {
            from = max;
          }
          range_instance.update({
            from: from
          });
        };

        $('.house_radio input[type="radio"]').on('change', function () {
          setDefaultDataTV();
          values_tv = getSlideTVValues();
          max = values_tv.length - 1;
          range_instance.update({
            skin: 'round',
            grid: false,
            hide_min_max: true,
            hide_from_to: true,
            values: values_tv,
            max: max
          });
          if (values_tv.length <= 1) {
            document.querySelectorAll('.jsSwitchPlus')[1].style.opacity = 0.5;
            document.querySelectorAll('.jsSwitchMinus')[1].style.opacity = 0.5;
          } else {
            document.querySelectorAll('.jsSwitchPlus')[1].style.opacity = 1;
            document.querySelectorAll('.jsSwitchMinus')[1].style.opacity = 1;
          }
        });

        $noTV.on('change', function () {
          const tv = $thisForm.find('.block_tv');

          if ($(this).prop('checked')) {
            $(this)
              .parents('.card_item')
              .addClass('no_tv')
              .find('button')
              .prop('disabled', true);
            tv.hide();
            checkprice_modal_on_load();
          } else {
            $(this)
              .parents('.card_item')
              .removeClass('no_tv')
              .find('button')
              .prop('disabled', false);
            tv.show();
            checkprice_modal_on_load();
          }
          activeRate.noTV = !activeRate.noTV;
        });

        $range.on('change', function () {
          from = $range.prop('value') - 1;
          checked = tvArraysBySpeed[$range.prop('value') - 1];
          currentTvPacket = tvArraysBySpeed[$range.prop('value') - 1];
          checked_package = values_tv_chanels_name[$range.prop('value') - 1];
          quantityMovies = getQuantityMovies(activeRate.speed, checked);
          $this.find('.content_value').text(checked);
          activeRate.channelCount = checked;
          $TvValue.text(checked);
          $TvRate.val(checked);
          $package.text(checked_package);
          $films.text(quantityMovies);
          !isNoTV ? $package.text(setActivePackageName(activeRate.speed, activeRate.channelCount)) : false;
          checkprice_modal();
        });

        $thisminus.on('click', function () {
          updateRange(-1);
          checked = tvArraysBySpeed[$range.prop('value') - 1];
          checked_package = values_tv_chanels_name[$range.prop('value') - 1];
          $this.find('.content_value').text(checked);
          activeRate.channelCount = checked;
          $TvValue.text(checked);
          $TvRate.val(checked);
          $films.text(quantityMovies);
          !isNoTV ? $package.text(setActivePackageName(activeRate.speed, activeRate.channelCount)) : false;
          checkprice_modal();
        });
        $thisplus.on('click', function () {
          updateRange(1);
          checked = tvArraysBySpeed[$range.prop('value') - 1];
          checked_package = values_tv_chanels_name[$range.prop('value') - 1];
          $this.find('.content_value').text(checked);
          activeRate.channelCount = checked;
          $TvValue.text(checked);
          $TvRate.val(checked);
          $package.text(checked_package);
          quantityMovies = getQuantityMovies(activeRate.speed, checked);
          $films.text(quantityMovies);
          !isNoTV ? $package.text(setActivePackageName(activeRate.speed, activeRate.channelCount)) : false;
          checkprice_modal();
        });
        document.querySelectorAll('.jsSwitchMinus')[1].disabled = true;
        values_tv.length <= 1 ? document.querySelectorAll('.jsSwitchPlus')[1].disabled = true : document.querySelectorAll('.jsSwitchPlus')[1].disabled = false;
      });

      function checkprice_modal() {
        const tv = $thisForm.find('.block_tv');
        if ($('.highBuildings-internet').prop('checked')) {
          $noTV.prop('disabled', true);
          $noTV.parent().css('opacity', '0.5');
        }
        if ($noTV.prop('checked') || $('.highBuildings-internet').prop('checked')) {
          $noTV
            .parents('.card_item')
            .addClass('no_tv')
            .find('button')
            .prop('disabled', true);
          tv.hide();
        } else {
          $noTV
            .parents('.card_item')
            .removeClass('no_tv')
            .find('button')
            .prop('disabled', false);
          tv.show();
        }

        let rightRate;

        if (!$noTV.prop('checked')) {
          rightRate = internetArrayByType.filter(rate => {
            let speed;
            activeRate.speed === '1000' ? speed = 1000 : speed = activeRate.speed;
            return rate.speed === +speed && rate.channel === activeRate.channelCount;
          });
        } else {
          rightRate = internetArrayByType.filter(rate => {
            let speed;
            activeRate.speed === '1000' ? speed = 1000 : speed = activeRate.speed;
            return rate.speed === +speed && rate.channel === 0;
          });
        }
        if (!HighRiseBuilding.length && !PrivateHouse.length) {
          if (!$noTV.prop('checked')) {
            if (interner_modal === 0) {
              document.querySelectorAll('.jsSwitchPlus')[1].disabled = true;
              document.querySelectorAll('.jsSwitchPlus')[1].style.opacity = 0.5;
            } else {
              document.querySelectorAll('.jsSwitchPlus')[1].disabled = false;
              document.querySelectorAll('.jsSwitchPlus')[1].style.opacity = 1;
            }
          }
        }

        if (rightRate.length) {
          if (rightRate[0].discount === 0) {
            $PriceCalc.text(rightRate[0].price);
          } else {
            $PriceCalc.text(rightRate[0].discount);
            $PriceCalcOld.text(rightRate[0].price);
          }
        }
      }

      checkprice_modal();

      async function checkprice_modal_on_load() {
        if (HighRiseBuilding.prop('checked')) {
          if (!$noTV.prop('checked')) {
            rightRate = await internetArrayByType.filter(rate => rate.type === 'highBuildings' && rate.speed === +activeRate.speed && rate.channel > 0);
          } else {
            rightRate = await internetArrayByType.filter(rate => rate.type === 'highBuildings' && rate.speed === +activeRate.speed && rate.channel === 0);
          }
        }

        if (PrivateHouse.prop('checked')) {
          if (!$noTV.prop('checked')) {
            rightRate = await internetArrayByType.filter(rate => rate.type === 'privateHouses' && rate.speed === +activeRate.speed && rate.channel > 0);
          } else {
            rightRate = await internetArrayByType.filter(rate => rate.type === 'privateHouses' && rate.speed === +activeRate.speed && rate.channel === 0);
          }
        }

        if (!HighRiseBuilding.length && !PrivateHouse.length) {
          if (!$noTV.prop('checked')) {
            rightRate = await internetArrayByType.filter(rate => rate.speed === +activeRate.speed && rate.channel > 0);
          } else {
            rightRate = await internetArrayByType.filter(rate => rate.speed === +activeRate.speed && rate.channel === 0);
          }
        }

        if (rightRate.length) {
          if (rightRate[0].discount === 0) {
            $PriceCalc.text(rightRate[0].price);
          } else {
            $PriceCalc.text(rightRate[0].discount);
            $PriceCalcOld.text(rightRate[0].price);
          }
          $PriceCalc.text(rightRate[0].price);
        }
      }

      checkprice_modal_on_load();

      function createResult() {
        let address = false;

        $thisForm.find('.jsSelectric').on('change', function () {
          if ($(this).val() == '0') {
            address = false;
          } else {
            address = true;
          }
        });

        $thisForm.find('.connection_data input[type="text"]').blur(function () {
          if (!$(this).val() == '' && address && step != 6) {
            $('.step_connection_data').prop('disabled', false);
          } else {
            $('.step_connection_data').prop('disabled', true);
          }
        });

        $thisForm.find('.level_wrapper [type="radio"]').on('change', function () {
          if ($(this).prop('checked')) {
            $('.step_connection_type').prop('disabled', false);
          }
        });
      }

      let step = 1;
      const $this_connection_step = $thisForm.find('.connection_step');
      const $this_connection_form = $thisForm.find('.connection_form');
      const $this_step_prev = $thisForm.find('.step_prev');
      const $this_step_next = $thisForm.find('.step_next');

      $this_step_prev.on('click', function () {
        if (step > 1) {
          $this_connection_step
            .find('.active')
            .removeClass('active')
            .prev()
            .removeClass('done')
            .addClass('active');
          $this_connection_form
            .find('.active')
            .removeClass('active')
            .prev()
            .addClass('active');
          step--;
          createResult();
        }
      });

      $this_step_next.on('click', function () {
        if (step < 7) {
          $this_connection_step
            .find('.active')
            .removeClass('active')
            .next()
            .addClass('active')
            .prev()
            .addClass('done');
          $this_connection_form
            .find('.active')
            .removeClass('active')
            .next()
            .addClass('active');
          step++;
          createResult();
        }
      });

      const initSlider = slider => {
        const slidesCount = $(slider).children().length;

        $(slider).not('.slick-initialized').slick({
          dots: false,
          infinite: false,
          slidesToShow: slidesCount > 4 ? 4 : slidesCount,
          slidesToScroll: 1,
          adaptiveHeight: true,
          cssEase: 'ease-in-out',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesCount > 3 ? 3 : slidesCount
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: slidesCount > 2 ? 2 : slidesCount
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
      };

      const destroySlider = slider => {
        $(slider).hasClass('slick-slider') && $(slider).slick('unslick');
      };

      $('.quality_levels').each(function () {
        const $this = $(this);
        const $thischeck = $this.find('#switch_checkbox');

        initSlider('.popular-slide');

        $thischeck.on('change', function () {
          destroySlider('.popular-slide');
          destroySlider('.popular-slide2');

          const currentSlider = $thischeck.prop('checked') ? '.popular-slide2' : '.popular-slide';

          if ($thischeck.prop('checked')) {
            $this.find('.level_wrapper.var1').removeClass('active');
            $this.find('.level_wrapper.var2').addClass('active');
          } else {
            $this.find('.level_wrapper.var2').removeClass('active');
            $this.find('.level_wrapper.var1').addClass('active');
          }

          initSlider(currentSlider);
        });
      });

      const $CountRouter = $thisForm.find('.jsCountRouter');
      $CountRouter.each(function () {
        const $this = $(this);
        const count_minus = $this.find('.jsEquipmentMinus');
        const count_plus = $this.find('.jsEquipmentPlus');

        count_minus.on('click', function () {
          const oldValue = $this.find('input[type="number"]').val();
          if (oldValue > 0) {
            const newVal = parseFloat(oldValue) - 1;
            $this.find('input[type="number"]').val(newVal);
          }
          cost_selected_equipment();
        });

        count_plus.on('click', function () {
          const oldValue = $this.find('input[type="number"]').val();
          const newVal = parseFloat(oldValue) + 1;
          $this.find('input[type="number"]').val(newVal);
          cost_selected_equipment();
        });
      });

      const $CountBox = $thisForm.find('.jsCountBox');
      $CountBox.each(function () {
        const $this = $(this);
        const count_minus = $this.find('.jsEquipmentMinus');
        const count_plus = $this.find('.jsEquipmentPlus');

        count_minus.on('click', function () {
          const oldValue = $this.find('input[type="number"]').val();
          if (oldValue > 0) {
            const newVal = parseFloat(oldValue) - 1;
            $this.find('input[type="number"]').val(newVal);
          }
          cost_selected_equipment();
        });

        count_plus.on('click', function () {
          const oldValue = $this.find('input[type="number"]').val();
          const newVal = parseFloat(oldValue) + 1;
          $this.find('input[type="number"]').val(newVal);
          cost_selected_equipment();
        });
      });

      function cost_selected_equipment() {
        const price_router = parseFloat($thisForm.find('.new_price_router').text());
        const number_router = parseFloat($thisForm.find('.jsCountRouter input[type="number"]').val());
        const price_box = parseFloat($thisForm.find('.new_price_box').text());
        const number_box = parseFloat($thisForm.find('.jsCountBox input[type="number"]').val());

        const all_price_router = price_router * number_router;
        const all_price_box = price_box * number_box;

        const all_sum = all_price_router + all_price_box;

        $thisForm.find('.cost_selected_equipment').text(all_sum);
        $thisForm.find('.cost_selected_equipment_input').val(all_sum);
      }
    });
  },

  modals: function () {
    $('.jsOpenModals').magnificPopup({
      removalDelay: 270,
      mainClass: 'my-mfp-slide-bottom'
    });
    $('.jsCloseModal').on('click', function (e) {
      e.preventDefault();
      $(this)
        .parents('.b_modal')
        .magnificPopup('close');
    });
  },

  tabs: function () {
    let tabs = $('.tabs');
    tabs.each(function () {
      const tabs = $(this);
      const tab = tabs.find('.tabs__tab');
      const content = tabs.find('.tabs__item');

      tab.each(function (index) {
        $(this).attr('data-tab', index);
      });

      function showContent(i) {
        tab.removeClass('-active');
        content.removeClass('-active').removeClass('-fade');
        tab.eq(i).addClass('-active');
        content.eq(i).addClass('-active');
        setTimeout(function () {
          content.eq(i).addClass('-fade');
        }, 1);
      }

      tab.on('click', function (e) {
        e.preventDefault();
        showContent(parseInt($(this).attr('data-tab')));
      });
    });

    tabs = $('.jsChannelsTabs');

    tabs.each(function () {
      tabs = $(this);
      const tab = tabs.find('.jsChannelsTabsTab');
      const content = tabs.find('.jsChannelsTabsItem');
      tab.each(function (index) {
        $(this).attr('data-tab', index);
      });

      function showContent(i) {
        tab.removeClass('-active');
        content.removeClass('-active').removeClass('-fade');
        tab.eq(i).addClass('-active');
        content.eq(i).addClass('-active');
        setTimeout(function () {
          content.eq(i).addClass('-fade');
        }, 1);
      }

      tab.on('click', function (e) {
        e.preventDefault();
        showContent(parseInt($(this).attr('data-tab')));
      });
    });
  },

  uTabs: function (selector) {
    const tabs = $(selector);
    const tabsButtons = tabs.find('.u-tabs__button');
    const tabsContent = tabs.find('.u-tabs__content');

    const tabsHandler = function () {
      const tabIndex = $(tabsButtons).index(this);
      tabsButtons.removeClass('active');
      $(this).addClass('active');

      tabsContent.fadeOut(0);
      tabsContent.eq(tabIndex).fadeIn();
    };

    tabsButtons.click(tabsHandler);
  },

  validate: function () {
    $('input[type="tel"]').mask('+38 (999) 999-99-99');
  },

  validateEmail: function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  accordeon: function () {
    const accord = $('.accord');
    const accord_btn = accord.find('.accord__btn');
    const accord_content = accord.find('.accord__content');

    accord_btn.on('click', function (e) {
      e.preventDefault();

      const $this = $(this);
      const $this_content = $this.closest('.accord__item').find('.accord__content');

      if ($this.hasClass('-active')) {
        $this.removeClass('-active');
        $this_content.slideUp();
      } else {
        accord_btn.removeClass('-active');
        accord_content.slideUp();
        $this.addClass('-active');
        $this_content.slideDown();
      }
    });
  },

  passiveListenerForTariffs: function() {
    $('.tariff-ajax').live('click', function(e){
      $('.jsOpenModals').magnificPopup({
        removalDelay: 270,
        mainClass: 'my-mfp-slide-bottom'
      });
      $('.jsCloseModal').on('click', function (e) {
        e.preventDefault();
        $(this)
          .parents('.b_modal')
          .magnificPopup('close');
      });
    });
  },

  getMessage: function (type, title, text, timer = 2) {
    const messageBox = document.querySelector('.message-block');
    let container;

    if (!messageBox) {
      container = document.createElement('div');
      container.classList.add('message-block');
    }

    const divMessage = document.createElement('div');

    divMessage.classList.add('get-message');

    divMessage.classList.add(type);
    const imgUrl = `themes/batyevka/assets/images/message-${type}.svg`;

    divMessage.innerHTML = `
      <img src=${imgUrl}>
      <div>
        <strong class='get-message_title'>${title}</strong>
        <p class='get-message_text'>${text}<p>
      </div>
      <button class='btn-off-message'>+</button>
    `;
    if (type === 'success') {
      if (timer) {
        setTimeout(() => {
          divMessage.style.left = '100%';
        }, timer * 1000);
        setTimeout(() => {
          divMessage.remove();
        }, (timer * 1000) + 650);
      }
    }

    if (messageBox) {
      messageBox.append(divMessage);
    } else {
      document.body.append(container);
      container.append(divMessage);
    }
    setTimeout(() => divMessage.classList.add('active'), 110);

    const messages = document.querySelectorAll('.get-message');
    messages.forEach((mess) => {
      mess.querySelector('.btn-off-message').addEventListener('click', () => {
        mess.classList.remove('active');
        setTimeout(() => mess.remove(), 650);
      });
    });
  },

  checkConnectionType: async (street, building) => {
    const adresses = await app.fetchData(urls.address);

    const [rightHouse] = adresses.filter(house => house.street === street && house.building === building);
    return rightHouse?.type;
  },

  mapPage: function () {
    if (!document.getElementById('coverage_map')) {
      return;
    }
    const street = document.querySelector('.jsSearchStreet');
    const house = document.querySelector('.label');
    const defaultBlock = document.querySelector('.default-block');
    const updateBlockGPON = document.querySelector('.gpon-update-block');
    const updateBlockUTP = document.querySelector('.utp-update-block');
    const defaultTitle = document.querySelector('.default-info_sub-title');
    const updateTitle = document.querySelector('.update-info_sub-title');
    let connectionType;
    document.querySelector('[href="#call_back"]').style.opacity = '0.5';
    if (window.innerWidth < 768) {
      document.querySelector('.address_info.bl_shadow').style.height = '100%;';
    } else {
      document.querySelector('.address_info.bl_shadow').style.height = '';
    }

    $('body').on('change', '.jsSearchHouse', async function (e) {
      connectionType = await app.checkConnectionType(street.value, e.target.value);
      checkForm();
    });

    function checkForm() {
      defaultBlock.style.display = 'block';
      updateBlockUTP.style.display = 'none';
      updateBlockGPON.style.display = 'none';

      setTimeout(() => {
        if (street.value !== '' && house.textContent.toLocaleLowerCase() !== 'дом') {
          defaultBlock.style.display = 'none';
          connectionType === 'UTP' && (updateBlockUTP.style.display = 'block');
          connectionType === 'GPON' && (updateBlockGPON.style.display = 'block');
          defaultTitle.style.display = 'none';
          updateTitle.style.display = 'block';
          house.style.color = 'inherit',
          document.querySelector('.address_info.bl_shadow').style = '';
          document.querySelector('[href="#call_back"]').style.opacity = '1';
          document.querySelector('.XXXMbit').style = 'display: block';
        } else {
          document.querySelector('.XXXMbit').style = 'display: none';
          house.style = '';
          document.querySelector('[href="#call_back"]').style.opacity = '0.5';
          defaultTitle.style.display = 'block';
          updateTitle.style.display = 'none';
          if (window.innerWidth < 768) {
            document.querySelector('.address_info.bl_shadow').style.height = '100%';
          } else {
            document.querySelector('.address_info.bl_shadow').style.height = '';
          }
        }
      }, 100);
    }
    const yearLabels = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
    const yearData = [423, 525, 345, 883, 123, 454, 235, 464, 456, 244, 976, 765];

    const monthLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    const monthData = [100, 112, 133, 99, 140, 98, 122, 133, 122, 111,
      99, 111, 100, 99, 122, 122, 188, 200, 199, 155,
      100, 112, 133, 99, 140, 98, 122, 133, 122, 111
    ];

    const weekLabels = ['понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота', 'неділя'];
    const weekData = [113, 122, 223, 123, 122, 100, 70];

    const dayLabels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
      '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
      '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
      '19:00', '20:00', '21:00', '22:00', '23:00'
    ];
    const dayData = [100, 112, 133, 99, 140, 98, 122, 133, 122, 111,
      99, 111, 100, 99, 122, 122, 188, 200, 199, 155,
      100, 122, 111, 80
    ];

    const ctx = document.getElementById('schedule-canvas').getContext('2d');
    // eslint-disable-next-line no-undef
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: yearLabels,

        datasets: [{
          label: 'My First dataset',
          backgroundColor: 'rgb(220, 102, 45)',
          borderColor: 'rgb(220, 102, 45)',
          data: yearData
        }]
      },
      options: {
        legend: {
          display: false,
          labels: {
            FontSize: 0
          }
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
    // eslint-disable-next-line no-undef
    Chart.defaults.global.defaultFontSize = 8;

    const scheduleItems = document.querySelectorAll('.schedule_item');

    scheduleItems.forEach(item => {
      item.addEventListener('click', (e) => {
        scheduleItems.forEach(item => item.classList.remove('active'));
        item.classList.add('active');
        e.preventDefault();
        updateCharts(item.dataset.datatype);
      });
    });

    function updateCharts(type) {
      switch (type) {
      case 'day':
        chart.data.datasets[0].data = dayData;
        chart.data.labels = dayLabels;
        break;
      case 'week':
        chart.data.datasets[0].data = weekData;
        chart.data.labels = weekLabels;
        break;
      case 'month':
        chart.data.datasets[0].data = monthData;
        chart.data.labels = monthLabels;
        break;
      case 'year':
        chart.data.datasets[0].data = yearData;
        chart.data.labels = yearLabels;
        break;
      }

      chart.update();
    }

    updateCharts('day');

    $('.alertBlock__slider').not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      infinite: true,
      arrows: true
    });
  },

  scrollSmooth: function (el) {
    el.scrollIntoView({
      behavior: 'smooth'
    });
  },

  filterAnyItems: function (items, dataType) {
    items.forEach((item) => {
      if (item.dataset.type === dataType) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  },

  channelsSlider: function () {
    const channelsWrapper = document.querySelector('.jsSliderChanelsCustom');
    if (!channelsWrapper) {
      return;
    }

    const createChannelsSlider = () => {
      const $filter_wrapper = $('.filter_wrapper ul');
      const categorys = [];
      let activeCategory = 'Все';

      function getChannels() {
        fetch('./themes/batyevka/assets/tv_mgg.xml')
          .then(res => res.text())
          .then(data => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'text/xml');
            const object = xmlDoc.getElementsByTagName('object');
            const channels = Array.from(object);
            channelsWrapper.innerHTML = '';

            channels.forEach(channel => {
              const title = channel.getAttribute('title');
              const category = channel.getAttribute('genres');
              const urlImg = channel.childNodes[5].attributes[1].value;
              const service_name = channel;
              categorys.push(category);

              let slide = null;
              let subscription = '';
              service_name.childNodes[16].childNodes.forEach(price => {
                subscription += price.childNodes[0].attributes[1].value + ' ';
              });
              slide = createSlide(urlImg, category, title, subscription);
              channelsWrapper.insertAdjacentElement('beforeend', slide);
            });
            createFilterList();
          });
      }

      function createFilterList() {
        const colectionCategory = new Set(categorys);

        [...colectionCategory].forEach(category => {
          category !== 'Радио'
            && $filter_wrapper.append(`<li data-cat="${category}">${category}</li>`);
        });

        $('.jsSliderChanelsCustom')
          .not('.slick-initialized')
          .slick(rulesSliderChanels());
      }

      getChannels();

      $filter_wrapper.on('click', 'li', function() {
        const category = $(this).data('cat');

        if (activeCategory !== category) {
          activeCategory = category;
          const li = $filter_wrapper.find('li');

          li.each(function() {
            $(this).removeClass('-active');

            if (category === $(this).data('cat')) {
              $(this).addClass('-active');

              $('.jsSliderChanelsCustom')
                .slick('slickUnfilter');

              $('.jsSliderChanelsCustom')
                .slick('slickFilter', function () {
                  return $('[data-category="' + category + '"]', this).length === 1;
                });
            }
          });
        }

        if (category === 'Все') {
          $('.jsSliderChanelsCustom').not('.slick-initialized').slick('slickUnfilter');
        }
      });

      const $filter_subscription = document.querySelectorAll('.subscription_tab_item');

      function changeSubscription() {
        $filter_subscription.forEach(item => {
          item.addEventListener('click', ({
            target
          }) => {
            if (target.name === 'subscription') {
              const channels = document.querySelectorAll('[data-subscription]');
              channels.forEach(channel => {
                if (channel.dataset.subscription.includes(target.value)) {
                  channel.style.opacity = '1';
                } else {
                  channel.style.opacity = '0.5';
                }
              });
            }
          });
        });
      }

      if ($filter_subscription) {
        changeSubscription(true);
      }
    };

    createChannelsSlider();

    function createSlide(urlImg, category, title, subscription) {
      const div = document.createElement('div');
      div.classList.add('item_slider');
      div.dataset.category = category;
      div.dataset.subscription = subscription.trim();
      div.innerHTML = `
            <div class="item_wrapper">
                <div class="img_wrapper"><img src="${urlImg}" alt=""></div>
                <div class="item_title"><span>${title}</span>
                  <p>Мегого</p>
                </div>
              </div>
          `.trim();
      return div;
    }

    function rulesSliderChanels() {
      return {
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 5,
        infinite: false,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            rows: 5
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 5,
            rows: 1,
            infinite: true

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.5,
            rows: 1,
            infinite: true
          }
        }
        ]
      };
    }
  },

  showСharacteristics: function () {
    $('.product_information-hover-list').on('click', function () {
      $(this).toggleClass('active');
    });
  }
};




$(() => app.init());

window.app = app;
